$archon: true;
@use "../../../resources/sass/variables";
@use "../../../resources/sass/breakpoints";

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px 12px;
  max-width: 900px;
  align-self: center;

  @include breakpoints.sm {
    gap: 24px 20px;
  }
}
