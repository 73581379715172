$archon: true;
@use "../../../resources/sass/variables";
@use "../../../resources/sass/breakpoints";

.container {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 100px;
  border-radius: variables.$border-radius;
  border: 1px solid variables.$level-1-border;
  padding: variables.$tiny-gap;

  @include breakpoints.sm {
    width: 185px;
    height: 100px;
    padding: 0;
  }
}

.orange {
  background: linear-gradient(125deg, #771B1B 0.18%, #AB7018 72.28%);
}

.purple {
  background: linear-gradient(125deg, #712E90 0.18%, #0D5357 72.28%);
}

.red {
  background: linear-gradient(125deg, #7D36B5 0.18%, #841010 72.28%);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  color: variables.$white-900;
  text-shadow: 0 2px 3px rgba(13, 13, 13, 0.75);
  font-size: 21px;
  font-weight: 900;

  @include breakpoints.sm {
    font-size: 24px;
  }
}

.title {
  color: variables.$white-900;
  text-align: center;
  text-shadow: 0 2px 3px rgba(13, 13, 13, 0.75);
  font-size: variables.$smaller-body-font-size;

  @include breakpoints.sm {
    font-size: variables.$body-font-size;
  }
}
