$archon: true;
@import '../../../resources/sass/variables';

.link, .button {
  font-size: $body-font-size;
  font-weight: bold;
  color: $white-700 !important;
  cursor: pointer;
  padding: 0 0 0 $small-gap;
  margin: 0;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:hover {
    color: $white-800 !important;
  }
}
