$archon: true;
@use "../../../resources/sass/breakpoints";
@use "../../../resources/sass/variables";

.timeline {
  display: flex;
  flex-direction: column;
  gap: 68px;

  a:hover {
    filter: brightness(1.15);
  }
}

.product {
  display: flex;
  align-items: center;
  gap: 32px;
  flex-direction: column-reverse;
}

.productOdd {
  @include breakpoints.md {
    margin-right: 120px;
    flex-direction: row-reverse;
  }
}

.productEven {
  @include breakpoints.md {
    margin-left: 120px;
    flex-direction: row;
  }
}

.productImage {
  border-radius: variables.$border-radius;
  border: 1px solid variables.$level-0-border;
}

.productImageNotAvailable {
  filter: brightness(0.3) grayscale(1);
}

.titleAndSince {
  display: flex;
  flex-direction: column-reverse;
  gap: 4px;
}

.since {
  font-size: 21px;
  font-weight: lighter;
}

.extraInfo {
  color: variables.$white-700;
  font-weight: 500;
  font-size: 18px;
}

.supportedGames {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include breakpoints.md {
    flex-direction: row;
    align-items: center;
  }
}

.supportedGameLabel {
  color: variables.$white-700;
  font-weight: 500;
  font-size: 21px;
}

.supportedGameIcons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  @include breakpoints.md {
    justify-content: flex-start;
  }
}
