$archon: true;
@use "../../../resources/sass/variables";
@use "../../../resources/sass/tools";

.container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 850px;
  align-self: center;
}

.gameContainer {
  display: flex;
  flex-direction: column;
  width: 180px;
  margin: 10px 0;
}

.gameName {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.gameName a {
  color: variables.$white-800;
  text-shadow: 0px 2px 3px rgba(13, 13, 13, 0.75);

  &:hover {
    color: variables.$white-900;
  }
}

.productsContainer {
  display: flex;
  justify-content: center;
}

.product {
  max-width: fit-content;
  margin: 12px 5px 9px;

  &:hover {
    filter: brightness(1.15);
  }

  img {
    box-shadow: 0 2px 3px 0 rgba(13, 13, 13, 0.75);
  }

  a {
    display: flex;
    align-items: center;
    gap: variables.$smaller-gap;
    font-size: variables.$smaller-font-size;
    color: variables.$white-800;
    text-transform: uppercase;
    font-weight: 500;

    div {
      display: flex;
      gap: variables.$tiny-gap;
      @include tools.text-shadow;

      span:first-child {
        color: variables.$link-blue;
      }

      span {
        line-height: 100%;
      }
    }
  }
}
