$archon: true;
@use "../../../resources/sass/variables";

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: variables.$large-gap variables.$larger-gap;
}

.class {
  display: flex;
  flex-direction: column;
  gap: variables.$smaller-gap;
}

.classLabel {
  font-weight: 500;
  color: variables.$white-800;
  align-self: center;
}

.specList {
  display: flex;
  flex-direction: column;
}

.spec {
  padding: 3px variables.$smaller-gap;

  &:hover {
    background: variables.$level-2-bg;
  }
}
