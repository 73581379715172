$archon: true;
@use "../../../resources/sass/variables";

.title {
  background: linear-gradient(90deg, #FE57F7 0%, #4D0EFF 100%);
  background-clip: text;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-weight: 900;
  font-style: italic;
  line-height: 47px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
  margin-right: -4px;
  text-transform: uppercase;
}

.heading {
  font-style: italic;
  font-size: 28px;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.includeMargin {
  margin-top: 40px;
  margin-bottom: 48px;
}

.badge {
  position: absolute;
  bottom: -28px;
  left: calc(50%);
  transform: translateX(-50%);
  text-transform: uppercase;
  font-size: 17px !important;
  font-weight: 700;
  letter-spacing: 1px;
  user-select: none;
}

.badgeTooltip {
  max-width: 300px;
  text-align: center;
  text-wrap: balance;
  color: variables.$white-800;
}
