$archon: true;
@use "../../../resources/sass/variables";
@use "../../../resources/sass/breakpoints";

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
}

.header {
  z-index: 4;
  width: 100%;
}

.main {
  flex: 1 0 auto;
  width: 100%;

  &WithBreadcrumbs {
    margin-top: var(--archon-header-desktop-height);
  }
}

.footer {
  max-width: 1024px;
  width: 100%;
  margin: 84px 0 variables.$xlarge-gap;
  z-index: 3;

  @include breakpoints.md {
    margin: 120px 0 variables.$xlarge-gap;
  }
}

.footerWithAds {
  max-width: 1392px;
}

.container {
  position: relative;
  z-index: 2;
  padding: 0 variables.$small-gap;
  max-width: 1024px;

  &WithAds {
    @include breakpoints.xl {
      display: grid;
      grid-template-columns: 1024px 320px;
      gap: variables.$larger-gap;
      max-width: calc(1344px + #{variables.$larger-gap});
    }
  }

  @include breakpoints.sm {
    margin: 0 auto;
    width: calc(100% - 2 * #{variables.$small-gap});
  }
}

.containerSidebar {
  display: none;

  @include breakpoints.xl {
    display: block;
  }
}

.containerSidebarSticky {
  position: sticky !important;
  top: calc(var(--archon-header-desktop-height) + #{variables.$small-gap});
}

.stickyFooterAd {
  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: center;
  z-index: 1;
  width: fit-content;
  justify-self: center;
}

.backgroundImageContainer {
  display: none;

  @include breakpoints.lg {
    display: block;
  }
}

.backgroundImage {
  z-index: 0;
  filter: brightness(0.27) contrast(1.1) saturate(0.2) blur(6px) !important;
}

$overlay-bg: variables.$level-0-bg-no-gradient;
$overlay-bg-transparent: variables.$level-0-no-gradient-transparent;

.backgroundImageOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, $overlay-bg-transparent, $overlay-bg);
}
