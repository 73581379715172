$archon: true;
.link {
  display: flex;
  justify-content: center;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.03);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.03);
  }
}

.container {
  transition: ease-in-out 300ms;
  border: 2px solid #161616;
  border-radius: 6px;
}

.container:hover {
  transition: ease-in-out 100ms;
  filter: brightness(1.2);
  animation: zoom-in-zoom-out 2s ease infinite;
}

.container img {
  border-radius: 4px;
}

// Inspired by: https://web.dev/css-border-animations/
@supports (background: paint(houdini)) {
  .container,
  .container::before,
  .container::after {
    box-sizing: border-box;
  }

  .container {
    --angle: 0deg;
    --opacity: 0.75;

    width: 152px;
    height: 202px;
  }

  .container:hover {
    --border-size: 2px;
    border: var(--border-size) dotted transparent;
    background-image: linear-gradient(
                    to right,
                    rgb(0 0 0 / 1),
                    rgb(0 0 0 / 1)
    ),
    conic-gradient(
                    from var(--angle),
                    rgb(191 88 243 / var(--opacity)) 180deg 270deg,
                    #00000000 270deg 360deg,
                    #00000000 0deg 180deg,
    );
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  @property --opacity {
    syntax: "<number>";
    initial-value: 0.75;
    inherits: false;
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .container:hover {
    animation: rotate 2.2s linear infinite, opacityChange 1s ease-in infinite alternate;
  }
}
