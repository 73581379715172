$archon: true;
@use "../../../resources/sass/variables";
@use "../../../resources/sass/breakpoints";

.container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;

  // AdPlacementPlaywireType
  &__type--sky_atf  {
    justify-content: center;
  }
}
