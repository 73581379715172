$archon: true;
@use "../../../resources/sass/breakpoints";
@use "../../../resources/sass/variables";

.footer {
  display: flex;
  flex-direction: column;
  gap: variables.$xlarge-gap;
  border-top: 1px solid variables.$level-0-border;
  padding-top: 32px;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: variables.$xlarge-gap;

  @include breakpoints.md {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include breakpoints.md {
    justify-content: space-between;
    align-items: flex-start;
  }
}

.titleAndMiscLinks {
  display: flex;
  flex-direction: column;

  @include breakpoints.md {
    align-items: flex-start;
  }
}

.miscLinks {
  font-weight: bold;
  color: variables.$white-700;

  a {
    color: unset;

    &:hover {
      color: variables.$white-900
    }
  }
}

.latestArticleLink {
  font-weight: 700;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: 21px;
  padding-bottom: variables.$smaller-gap;

  @include breakpoints.md {
    font-size: variables.$body-font-size;
    padding-bottom: 0;
  }
}

.rightSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: variables.$tiny-gap;

  @include breakpoints.md {
    align-items: flex-end;
  }
}

.supportedGamesTitle {
  border-bottom: 1px solid variables.$white-500;
  font-size: 21px !important;
  padding-bottom: variables.$smaller-gap;

  @include breakpoints.md {
    font-size: 17px !important;
  }
}

.gameLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;

  a {
    color: variables.$white-700;

    &:hover {
      color: variables.$white-900
    }
  }

  @include breakpoints.md {
    align-items: flex-end;
    font-size: variables.$body-font-size;
  }
}

.gameLink {
  display: flex;
  gap: variables.$smaller-gap;
  align-items: center;
  padding: 10px 0;

  @include breakpoints.md {
    padding: 0;
  }
}

.bottom {
  text-align: center;
  font-size: 15px;
  color: variables.$white-800;
}
