$archon: true;
@import "../../../resources/sass/variables";

.advertisements {
  display: inline-block;
  position: relative;
  font-size: $small-font-size;
  overflow: hidden;

  &Chrome {
    padding: 4px 8px;
    background: $level-1-bg;
    border: 1px solid $level-0-border;
  }

  &RightAligned {
    align-items: flex-end;
  }

  &Rounded {
    border-radius: $border-radius;
  }
}

.header {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}

.advertisementsTitle, .removeAdsLink {
  color: $white-800 !important;
}

.spots {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $smaller-gap;

  &Horizontal {
    flex-direction: row;
    justify-content: center;
  }

  > * {
    background: $level-0-bg;
    border-radius: $border-radius;
    border: 1px solid $level-0-border;
    overflow: hidden;
  }
}
