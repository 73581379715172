$archon: true;
@use "../../../resources/sass/variables";
@use "../../../resources/sass/breakpoints";

.adPlacement {
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: variables.$level-0-bg;

  &Rounded {
    border-radius: variables.$border-radius;
  }

}
// AdPlacementLocation
// NOTE: Should be in sync with resources/js/components/AdPlacement/AdPlacement.scss
.type--sideRailNarrow {
  width: 160px;
  height: 600px;
}
.type--sideRailWide {
  width: 300px;
  height: 600px;
  justify-content: center;
}
.type--characterGear {
  width: 336px;
  height: 280px;
}
.type--cornerVideo, .type--richMedia {
  // Required for ad placements to be treated as visible
  width: 1px;
  height: 1px;
}
.type--stickyFooter {
  // Mobile Sizes
  min-width: 300px;
  min-height: 50px;
  max-height: 100px;
  @include breakpoints.md {
    // Tablet+
    height: 90px;
    min-width: 728px;
  }
}
.type--leaderboardTall {
  width: 970px;
  height: 250px;
}
