$archon: true;
@use "../../../resources/sass/variables";
@use "../../../resources/sass/breakpoints";

@mixin backgroundLayer {
  display: block;
  position: absolute;
  overflow-y: clip;
}

.backgroundImage {
  display: none;

  @include breakpoints.lg {
    @include backgroundLayer;
    width: 1600px;
    left: calc(50% - 800px);
    right: calc(50% + 800px);
    top: -240px;
    bottom: 0;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: opacity 0.3s ease-in-out;
      filter: brightness(0.65) contrast(1.1) saturate(0.2) blur(6px);
    }
  }
}

$overlay-bg: variables.$level-0-bg-no-gradient;
$overlay-bg-transparent: variables.$level-0-no-gradient-transparent;

.backgroundImageOverlay {
  display: none;

  @include breakpoints.lg {
    @include backgroundLayer;
    width: 1650px;
    left: calc(50% - 825px);
    right: calc(50% + 825px);
    top: 0;
    bottom: 0;

    background:
            linear-gradient(to left, $overlay-bg-transparent, $overlay-bg-transparent 40%, $overlay-bg),
            linear-gradient(to right, $overlay-bg-transparent, $overlay-bg-transparent 40%, $overlay-bg),
            linear-gradient(to top, $overlay-bg-transparent, $overlay-bg-transparent 30%, $overlay-bg 70%),
            linear-gradient(to bottom, $overlay-bg-transparent, $overlay-bg-transparent 90%, $overlay-bg 100%);
  }
}

.textContent {
  text-align: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      max-width: 700px;
      color: variables.$white-700;
    }
  }
}

.accentContainer {
  width: 100%;
  border-top: 1px solid variables.$level-0-border-dark;
  border-bottom: 1px solid variables.$level-0-border-dark;
  background: variables.$level-0-bg-accent;
  padding: 84px 0;
  margin-bottom: 60px;
}

.learnMoreLink {
  font-size: 20px;
  font-weight: 700;
}

.meetTheTeamContent {
  margin-top: 60px;

  p {
    max-width: 858px;
  }
}

.meetTheTeamContentRight {
  @include breakpoints.md {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
