$archon: true;
@use "../../../resources/sass/breakpoints";
@use "../../../resources/sass/objects";

@import "../../../resources/sass/variables";

.header {
  display: flex;
  justify-content: center;
  padding: $smaller-gap 0;
  width: 100%;

  &WithBreadcrumbs {
    background: $level-0-bg;
    border-bottom: 1px solid $level-0-border;
    position: fixed;
  }
}

.headerContent {
  display: flex;
  gap: $small-gap;
  justify-content: space-between;
  align-items: center;
  max-width: 1432px;
  width: 100%;
  padding: 0 $smaller-gap;
}

.logoAndBreadcrumbs {
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 16px;
}

.logoContainer {
  &:hover {
    filter: brightness(1.1);
  }
}

.breadcrumbs {
  display: none;
  align-items: center;
  gap: 18px;
  white-space: nowrap;

  @include breakpoints.lg {
    display: flex;
  }
}

.breadcrumb {
  position: relative;
  padding: 7px 0;
  font-size: $body-font-size;
  color: $white-700;

  a {
    color: $white-700;

    &:hover {
      color: $white-800;

      svg {
        fill: $white-800;
      }
    }
  }
}

.lastBreadcrumb {
  font-weight: 500;
}

.hoveredBreadcrumb {
  color: $white-900 !important;

  svg {
    fill: $white-900;
  }
}

.rootBreadcrumb {
  @include objects.archon-style-text;
}

.breadcrumbMenu {
  position: absolute;
  top: calc(100% - 4px);
  left: -1 * $small-gap;
  min-width: calc(100% + 20px);
  width: fit-content;

  display: flex;
  flex-direction: column;

  background: $level-1-bg;
  border: 1px solid $level-1-border;
  border-radius: $border-radius;
}

.breadcrumbComponentMenu {
  padding: $smaller-gap $small-gap;
  transform: translateX(-45%);
  left: 45%;
}

a.breadcrumbOption {
  display: block;
  white-space: nowrap;
  width: calc(100% - 2 * $small-gap);
  padding: $smaller-gap $small-gap;
  color: $white-800;

  &:hover {
      background: $level-2-bg;
  }
}

.gameSelect {
  display: block;
  max-width: 250px;

  &WithBreadcrumbs {
    display: none;
    
    @include breakpoints.lg {
      display: block;
    }
  }
}

.menuToggle {
  display: block;

  @include breakpoints.lg {
    display: none;
  }

  button {
    display: flex;
    align-items: center;
  }
}

.menuUnderlay {
  position: fixed;
  top: var(--archon-header-mobile-height);
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);

  @include breakpoints.lg {
    display: none;
  }
}

.menu {
  position: fixed;
  width: inherit;
  top: var(--archon-header-mobile-height);
  background: $level-0-bg;
  border-bottom: 1px solid $level-0-border;
  max-height: calc(100vh - var(--archon-header-mobile-height));
  overflow-y: auto;
  padding-bottom: $large-gap;

  ul {
    list-style: none;
    padding: 0 $small-gap;
    margin: $smaller-gap;
  }

  li {
    padding: $smaller-gap 0;

    &:first-child {
      padding-bottom: 2px;
    }

    a {
      color: $white-800;
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    ul {
      list-style: initial;
      margin-left: $xlarge-gap;
      margin-bottom: 0;

      a {
        text-decoration-color: $white-500;
      }
    }
  }

  @include breakpoints.lg {
    display: none;
  }
}
